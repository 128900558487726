import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Business from "../images/v2/Pricing/Business.svg";
import Starter from "../images/v2/Pricing/Starter.svg";
import Enterprisies from "../images/v2/Pricing/Enterprisies.svg";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircle from "@material-ui/icons/CheckCircle";
import FlashOn from "@material-ui/icons/FlashOn";
import CardMedia from "@material-ui/core/CardMedia";
import EffortlessMigration from "../images/v2/Pricing/Effortless Migration.svg";
import PreminentDataSecurity from "../images/v2/Pricing/Preminent Data Security.svg";
import SeamlessCustomerSupport from "../images/v2/Pricing/Seamless Customer Support.svg";
import PricingSvg from "../images/v2/Pricing/Pricing.svg";
import joinImage from "../images/v2/Pricing/Vector_3.svg";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import WhiteButton from "../components/v2/WhightButton";
import pricingImage from "../images/v2/Pricing/PricingImage.webp";
import useWidth from "../hooks/useWidth";
import SEO from "../components/SEO";
import AccordionHeader from "../components/v2/AccordionHeader";
import { Link } from "gatsby";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  root: {
    backgroundImage:
      "linear-gradient(to bottom, #e7f2fe, rgba(231, 242, 254, 0))",
  },
  cardPricing: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  featureItemFirst: {
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(4),
  },
  featureItem: {
    borderLeft: "1px solid",
    borderRadius: theme.shape.borderRadius * 2.5,
    borderColor: "#e2f0ff",
    padding: theme.spacing(4),
  },
  collapseDivider: {
    backgroundColor: "#eaf0f6",
  },
  JoinRoot: {
    background: `url(${joinImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  heading: {
    fontSize: 20,
    fontWeight: 600,
    flexBasis: "100%",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      flexBasis: "100%",
    },
  },
  faqP: {
    marginTop: "-14px",
    borderTop: "solid 1px #e5e7e9",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  media: {
    width: 87.6,
    height: 115,
    margin: 21,
  },
  businessMedia: {
    width: 87.6,
    height: 115,
    margin: "21px 0px 40px 21px",
  },
  pricecardRoot: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      flexDirection: "column",
    },
  },
  priceCard: {
    minWidth: 382,
    padding: 15,
    boxShadow: "10px 20px 50px 0 rgb(2 145 174 / 16%)",
    [theme.breakpoints.down("md")]: {
      minWidth: "313px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
      maxWidth: 313,
    },
    "&:hover $buyBtn": {
      color: "#FFF",
      backgroundColor: "#ff7a59",
    },
  },
  FeatureIcon: {
    color: "#009f53",
    minWidth: 38,
  },
  featureHeader: {
    color: "#009f53",
  },
  recomandIcon: {
    color: "#ffb400",
  },
  featureListItem: {
    padding: 0,
  },
  buyBtn: {
    width: 312.2,
    height: 67,
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      width: 230,
    },
  },
  contactUsBtn: {
    width: 194.6,
    height: 44.1,
  },
  iconBtn: { opacity: 0.6, backgroundColor: "#eaf0f6" },
  cardHeader: {
    fontSize: 28,
    fontWeight: "bold",
  },
  expandIcon: {
    color: "#0291ae",
    fontSize: 34,
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqRoot: {
    marginBottom: theme.spacing(2),
    borderRadius: 10,
    boxShadow: "7px 15px 20px 0 rgb(171 171 171 / 16%)",
    border: "solid 1px #e5e7e9",
    overflow: "hidden",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  header: {
    paddingTop: "58px",
    marginBottom: "75px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "5px",
      marginBottom: "7px",
    },
  },
  pricingDescription: {
    color: "rgb(46, 63, 79)",
    fontSize: "18px",
    fontWeight: "400",
  },
}));

const pricingCardDetails = [
  {
    title: "Starter",
    user: "5 users",
    monthly: "4",
    annually: "10",
    description: "Powerful CRM software for small businesses.",
    feature: ["Opportunity Management", "Custom Fields", "Data Management"],
    buttonVariant: "outlined",
    logo: Starter,
    alt: "CRM pricing comparison between starter pricing plan",
  },
  {
    title: "Business",
    user: "10-12 users",
    description: "Dynamic CRM tool for medium-level organisations.",
    subheader: "Most popular",
    monthly: "7",
    annually: "15",
    feature: ["Email Integration", "Browser Extension", "Lead Management"],
    buttonVariant: "outlined",
    logo: Business,
    alt: " Business pricing plan",
  },
  {
    title: "Enterprise",
    user: "50+ users",
    monthly: "",
    annually: "",
    description: "Productive CRM system for large companies.",
    feature: ["Unlimited Records", "Team Security", "Dedicated Support"],
    buttonVariant: "outlined",
    logo: Enterprisies,
    alt: "enterprise pricing plan",
  },
];
const Plans = [
  {
    title: "Seamless Customer Support",
    logo: SeamlessCustomerSupport,
    alt: "Headphone icon - the best customer support by the best CRM system ",
  },
  {
    title: "Effortless Migration",
    logo: EffortlessMigration,
    alt: "Icon to indicate effortless migration with various CRM pricing models",
  },
  {
    title: (
      <>
        Preeminent <a href="https://peppercloud.com/crm-data-security-protection"> Data Security</a>
      </>
    ),
    logo: PreminentDataSecurity,
    alt: "Icon to indicate data security with every crm pricing model",
  },
];

function Pricing() {
  const width = useWidth();
  const classes = useStyles();

  const [customerPrefer, setCustomerPrefer] = React.useState(0);

  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const prefer = [
    {
      title: "Boosts your sales",
      description:
        "With various CRM features and functionalities, Pepper Cloud helps you efficiently deliver remarkable experiences to your customers and increase sales manifold.",
    },
    {
      title: "Saves time and effort",
      description: (
        <>
          Provide the freedom your sales team deserves by organising your{" "}
          <a href="https://peppercloud.com/sales-pipeline-management-tool">pipeline </a>,
          strategising the process, and{" "}
          <a href={"https://peppercloud.com/integration"}>
            visualising the performance
          </a>
          .
        </>
      ),
    },
    {
      title: "No shuffling between tools",
      description: (
        <>
          Integrate the sales-boosting tools with Pepper Cloud CRM and avoid
          sifting through multiple tools. Work efficiently with plug-and-play
          <a href="https://peppercloud.com/integration">
            CRM system integrations
          </a>
          .
        </>
      ),
    },
  ];
  const FAQ = [
    {
      title: "How can we choose among various pricing options?",
      description:
        "Choose the starter pack from the CRM system price plans if you are moving from the manual or spreadsheet management to CRM. Business Plan is good for companies who are already using an existing CRM but now shifting to a cloud-based solution. For big and heavy users, enterprise plans are the best as it comes with unlimited priority support.",
    },
    {
      title: "Can we change our pricing plan in between our billing period?",
      description: (
        <>
          You can move from the monthly billing cycle to the annual billing
          cycle anytime you want. However, we do not support moving from a
          yearly plan to a monthly plan before the expiry of the period. For
          shifting to enterprise-level plans, please{" "}
          <a href="https://peppercloud.com/contact"> contact our sales team</a>.
        </>
      ),
    },
    {
      title: "What language does Pepper Cloud CRM support?",
      description:
        "Currently, Pepper Cloud is available only in English. Soon, you will enjoy our services in Thai, Chinese (Simplified) and Bahasa.",
    },
    {
      title: "Can I export all my data if I stop using Pepper Cloud CRM?",
      description:
        "Absolutely! Your data is your property so you can take export of your data whenever you like. You can also schedule an automatic data export with Pepper Cloud.",
    },
    {
      title: "How to avail PSG grant for Pepper Cloud CRM?",
      description: (
        <>
          <a href="https://peppercloud.com/contact">Contact</a> our friendly
          customer support. They will help you. You can learn more about{" "}
          <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
            {" "}
            PSG grants
          </a>{" "}
          on our blog.
        </>
      ),
    },
  ];
  return (
    <div>
      <SEO
        canonical="/crm-platfrom-pricing"
        description="Stop paying on multiple tools to manage your sales business. Just get All-in-One sales CRM at most affordable CRM system pricing - Starter, business, and enterprise plan"
        keywords="CRM Pricing, 
        CRM platfrom pricing,
				pricing plans,
				CRM online pricing,
				CRM system price,
				CRM price"
        pathname="/crm-platfrom-pricing"
        title="CRM Platfrom Pricing Plan | Pepper Cloud CRM"
      />
      <div className={classes.root}>
        <Box
          alignItems="center"
          className={classes.header}
          component={Container}
          display="flex !important"
          flexDirection="column"
          textAlign="center"
        >
          <HeaderTypography component="h1" my={3}>
            Simplified pricing plans for strong relationships
          </HeaderTypography>

          <ParagraphTypography component="h2" fontSize={22} m={0}>
            With competitive CRM pricing plans, experience extensive CRM
            features to accelerate your business growth.
          </ParagraphTypography>
        </Box>
        <Container component="main">
          <Box m={1}>
            <Grid
              alignItems="flex-end"
              className={classes.pricecardRoot}
              container
              spacing={5}
            >
              {pricingCardDetails.map((tier) => (
                // Enterprise card is full width at sm breakpoint
                <Grid
                  item
                  key={tier.title}
                  md={4}
                  sm={tier.title === "Enterprise" ? 12 : 6}
                  xs={12}
                >
                  <Card className={classes.priceCard}>
                    <CardMedia
                      alt={tier.alt}
                      className={
                        tier.title === "Business"
                          ? classes.businessMedia
                          : classes.media
                      }
                      image={tier.logo}
                      title={tier.alt}
                    />
                    {tier.title === "Business" && (
                      <Box
                        alignItems="center"
                        display="flex"
                        padding="0px 0px 0px 15px"
                      >
                        <FlashOn className={classes.recomandIcon} />
                        <Typography color="textSecondary" variant="body2">
                          RECOMMENDED
                        </Typography>
                      </Box>
                    )}
                    <Box padding={"0px 61px 0px 18px"}>
                      <Box component="h2" fontSize="28px" fontWeight="600">
                        {tier.title}
                      </Box>
                      <Box color="#516f90" fontSize="20px" fontWeight="600">
                        {tier.user}
                      </Box>
                      <ParagraphTypography>
                        <h3 className={classes.pricingDescription}>
                          {tier.description}
                        </h3>
                      </ParagraphTypography>
                    </Box>
                    <CardContent>
                      {/*<div className={classes.cardPricing}>*/}
                      {/*	{tier[billType] ? (*/}
                      {/*		<>*/}
                      {/*			<Typography color="textPrimary" component="h2" variant="h3">*/}
                      {/*				${tier[billType]}/*/}
                      {/*			</Typography>*/}
                      {/*			<Box color="#2e3f4f" fontSize="15px" height="37px" width="50px">*/}
                      {/*				user {billType}*/}
                      {/*			</Box>*/}
                      {/*		</>*/}
                      {/*	) : (*/}
                      {/*		<Button*/}
                      {/*			className={classes.contactUsBtn}*/}
                      {/*			color="primary"*/}
                      {/*			onClick={() => setOpen(true)}*/}
                      {/*			variant="outlined"*/}
                      {/*		>*/}
                      {/*			Contact Us*/}
                      {/*		</Button>*/}
                      {/*	)}*/}
                      {/*</div>*/}
                      <Typography
                        className={classes.featureHeader}
                        variant="h6"
                      >
                        Feature included
                      </Typography>
                      <List>
                        {tier.feature.map((line) => (
                          <ListItem
                            classes={{
                              root: classes.featureListItem, // class name, e.g. `classes-nesting-root-x`
                            }}
                            key={line}
                          >
                            <ListItemIcon className={classes.FeatureIcon}>
                              <CheckCircle />
                            </ListItemIcon>
                            <ListItemText primary={line} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                    <CardActions>
                      <Button
                        className={classes.buyBtn}
                        color="secondary"
                        component={Link}
                        to="/contact"
                        variant={tier.buttonVariant}
                      >
                        Contact us
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>

        <Box mt={10}>
          <Divider light />
        </Box>
      </div>
      <Box
        alignItems="center"
        component={Container}
        display="flex !important"
        flexDirection="column"
        py={{ sm: 0, xs: 0 }[width] || 3}
        textAlign="center"
      >
        <HeaderTypography component="h2" mb={0} mt={3}>
          Various Plans, One Commitment
        </HeaderTypography>
        <ParagraphTypography fontSize={20} maxWidth={720} mb={{ md: 10 }}>
          Various pricing plans, one strong commitment
        </ParagraphTypography>

        <Grid container spacing={4}>
          {Plans.map((each, index) => (
            <Grid item key={each.title} md={4} sm={6} xs={12}>
              <div
                className={
                  index === 0 ? classes.featureItemFirst : classes.featureItem
                }
              >
                <Box
                  alt={each.alt}
                  component="img"
                  my={3}
                  src={each.logo}
                  width={95}
                />
                <Box component="h2" fontSize="24px" fontWeight="600">
                  {each.title}
                </Box>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box my={4}>
        <Divider light />
      </Box>
      <Box
        alignItems="center"
        display="flex !important"
        flexDirection="column"
        mt={{ xl: 6, md: 6 }}
        py={{ xl: 2, md: 2 }}
        textAlign="center"
      >
        <Container>
          <Box display="flex" justifyContent="center">
            <HeaderTypography component="h2" maxWidth={700} my={1}>
              Pepper Cloud CRM is a perfect fit for your business
            </HeaderTypography>
          </Box>
        </Container>
      </Box>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item md={6} sm={12}>
            <AccordionHeader
              // description="Focus on improving the performance lags with the help of the activity dashboard."
              alt="Icon to indicate CRM price"
              img={PricingSvg}
              title="Reasons why Pepper Cloud is worth your money"
            />
            <Box mb={1} mt={4}>
              <Divider light />
            </Box>
            {prefer.map((each, index) => (
              <div key={each.title}>
                <Box m={0} px={2}>
                  <Box
                    alignItems="center"
                    color="common.white"
                    display="flex"
                    justifyContent="space-between"
                    my={1}
                    onClick={() =>
                      setCustomerPrefer(index === customerPrefer ? -1 : index)
                    }
                  >
                    <Box
                      color="text.secondary"
                      component="h4"
                      fontSize="h6.fontSize"
                      fontWeight="600"
                      m={0}
                    >
                      {each.title}
                    </Box>
                    <IconButton
                      aria-label="Expand or Collapse"
                      className={classes.expendButton}
                      color="default"
                    >
                      {index !== customerPrefer ? (
                        <AddIcon fontSize="small" />
                      ) : (
                        <RemoveIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Box>
                  <Collapse in={customerPrefer === index}>
                    <ParagraphTypography fontSize={16} mr={6} mt={2}>
                      {each.description}
                    </ParagraphTypography>
                  </Collapse>
                  {index + 1 !== prefer.length && (
                    <Divider className={classes.collapseDivider} light />
                  )}
                </Box>
              </div>
            ))}
          </Grid>
          <Grid item md={6} sm={12}>
            <Box mt={4} textAlign="center">
              <img
                alt="Icon to indicate CRM price"
                className={classes.img}
                src={pricingImage}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <br />
      <Box bgcolor={"#f2f9f9"} pb={4}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          py={3}
          textAlign="center"
        >
          <HeaderTypography component="h2" my={3}>
            CRM pricing plans - Frequently asked questions
          </HeaderTypography>
        </Box>
        <Container>
          {FAQ.map((each, index) => {
            return (
              <Accordion
                className={classes.faqRoot}
                expanded={expanded === index}
                key={each.title}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  aria-controls="panel2bh-content"
                  expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                >
                  <Typography className={classes.heading} component={"h3"}>
                    {each.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.faqP}>
                    <Box
                      color="#2e3f4f"
                      fontSize="16px"
                      margin="23px 0 0 8.8px"
                    >
                      {each.description}
                    </Box>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Container>
        {/*<Box*/}
        {/*	alignItems="center"*/}
        {/*	component={Container}*/}
        {/*	display="flex !important"*/}
        {/*	flexDirection="column"*/}
        {/*	py={6}*/}
        {/*	textAlign="center"*/}
        {/*>*/}
        {/*	<Button color="secondary" onClick={() => setOpen(true)} size="large" variant="contained">*/}
        {/*		Try for Free*/}
        {/*	</Button>*/}
        {/*</Box>*/}
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "3rem 1rem",
        }}
      >
        <Box maxWidth={1015} textAlign="center">
          <Box
            onClick={() =>
              window.open(
                "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
              )
            }
            sx={{ cursor: "pointer" }}
            component={"img"}
            src={IMDA_SME_Large}
            maxWidth={"100%"}
            alt={"IMDA Pre-approved Solution Provider"}
            margin={0}
            width={"100%"}
            mb={0}
          />
          <Box className={classes.SmeStyling}>
            <ParagraphTypography
              color="text.secondary"
              fontSize={16}
              sx={{ padding: 0, margin: 0 }}
              textAlign="center"
              className={classes.smeFooterText}
            >
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.
            </ParagraphTypography>
          </Box>
        </Box>
      </Container>
      <Box
        alignItems="center"
        className={classes.JoinRoot}
        display="flex !important"
        flexDirection="column"
        px={1}
        py={8}
        textAlign="center"
      >
        <HeaderTypography
          color="common.white"
          component="h2"
          fontSize={38}
          my={0}
          textAlign="center"
        >
          Join the sales experts!
        </HeaderTypography>
        <ParagraphTypography
          color="common.white"
          component="h3"
          fontSize={24}
          mb={6}
          mt={0}
          textAlign="center"
        >
          Double your sales growth with{" "}
          <a href="https://peppercloud.com/"> Singapore’s #1 sales CRM</a>{" "}
          software for small businesses.
        </ParagraphTypography>
        <Box flexDirection="column">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            Request for demo
          </WhiteButton>
        </Box>
      </Box>
    </div>
  );
}

export default Pricing;
